<template>
  <b-card :title="$t('overview')" class="mb-4">
    <b-card-text class="overview-card-grid-container">
      <div>#</div>
      <div>
        {{ projectNumber }}
      </div>

      <div>{{ $t('installationAt') }}</div>
      <div>
        <span v-if="project.newestInstallationAppointment">{{
          $datetime(project.newestInstallationAppointment.installationStartAt)
        }}</span>
        <span v-else>N/A</span>
      </div>

      <div>{{ $t('name') }}</div>
      <div>{{ project.customer.lastname }}, {{ project.customer.firstname }}</div>

      <div>{{ $t('installation') }} {{ $t('team') }}</div>
      <div>
        <template v-if="project.newestInstallationAppointment">
          <div
            v-for="employee in project.newestInstallationAppointment.employees"
            :key="employee.number"
          >
            <div>{{ employee.firstname }} {{ employee.lastname }}</div>
          </div>
        </template>
        <span v-else>N/A</span>
      </div>

      <div>{{ $t('address') }}</div>
      <div>
        <a :href="generateGoogleMapsLink(project.customer)" target="_blank">
          {{ project.customer.street }} {{ project.customer.streetNumber
          }}{{ project.customer.streetNumberSuffix }}<br />
          {{ project.customer.zip }} {{ project.customer.city }}
        </a>
      </div>
      <div>{{ $t('powerCompany') }}</div>
      <div v-if="project.powerCompany">{{ project.powerCompany.company }}</div>
    </b-card-text>
  </b-card>
</template>

<script>
import { generateGoogleMapsLink, startTeamsCall } from '@/helpers/integration';
export default {
  props: {
    project: { type: Object },
    projectNumber: null,
  },
  methods: {
    generateGoogleMapsLink,
  },
};
</script>

<style scoped lang="scss">
.overview-card-grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  column-gap: 1em;
  row-gap: 0.5em;
}
</style>
