<template>
  <div class="project-accounting">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('accountingAdditionalAcPositions') }}</strong>
        </legend>

        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.accountingAdditionalAcPositions"
            :config="editorConfig"
          ></ckeditor>
        </b-form-group>

        <legend>
          <strong>{{ $t('accountingDescription') }}</strong>
        </legend>

        <b-form-group horizontal breakpoint="md">
          <ckeditor
            :editor="editor"
            v-model="project.accountingDescription"
            :config="editorConfig"
          ></ckeditor>
        </b-form-group>
      </b-col>

      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('offerAttachments') }}</strong>
          <ZipDownload
            attachmentType="offerAttachments"
            :attachments="project.offerAttachmentsZip"
            :project="project"
          />
        </legend>
        <AttachmentList
          :attachments="project.offerAttachments"
          attachmentType="offerAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>
          <strong>{{ $t('invoiceAttachments') }}</strong>
          <ZipDownload
            attachmentType="invoiceAttachments"
            :attachments="project.invoiceAttachmentsZip"
            :project="project"
          />
        </legend>
        <AttachmentList
          :attachments="project.invoiceAttachments"
          attachmentType="invoiceAttachments"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
        ></AttachmentList>

        <legend>
          <strong>{{ $t('upload') }}</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('projectAccountingAttachmentType')"
          label-for="projectAccountingAttachmentType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('projectAccountingAttachmentType')"
            v-model="selectedProjectAccountingAttachmentType"
            id="projectAccountingAttachmentType"
            :options="filteredProjectAccountingAttachmentTypes"
            :allow-empty="false"
            :show-labels="false"
            v-on:search-change="onProjectAccountingAttachmentTypeSearch"
            :internal-search="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>

          <DropzoneUpload
            class="mt-2"
            :complete="isAccountingAttachmentUploadComplete"
            :parentId="project.id"
            :parentType="selectedProjectAccountingAttachmentType"
            :uploadCallback="onUploadProjectAttachments"
            :disabled="isUploadDisabled"
            :multiUpload="true"
            v-on:dropzone-upload:reset="isAccountingAttachmentUploadComplete = false"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12" md="12" sm="12">
        <legend>
          <strong>{{ $t('invoices') }}: {{ $t('approvement') }}</strong>
        </legend>
        <div class="verification-flex m-1">
          <VerificationState
            :project="project"
            verificationStateType="invoiceApprovementVerificationState"
          />
          <Card>
            <template #title>{{ $t('invoiceApprovementNote') }} </template>
            <template #content>
              <b-form-textarea
                v-model="project.invoiceApprovementNote"
                placeholder="..."
                rows="4"
                max-rows="4"
              ></b-form-textarea>
            </template>
          </Card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import ZipDownload from '@/components/ZipDownload';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import VerificationState from '@/components/VerificationState.vue';
import Card from 'primevue/card';
export default {
  components: {
    Multiselect,
    DropzoneUpload,
    AttachmentList,
    ZipDownload,
    ckeditor: CKEditor.component,
    VerificationState,
    Card,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      isAccountingAttachmentUploadComplete: false,
      selectedProjectAccountingAttachmentType: null,
      filteredProjectAccountingAttachmentTypes: [],
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    isUploadDisabled() {
      return this.selectedProjectAccountingAttachmentType === null ? true : false;
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues', 'uploadProjectAttachment', 'deleteProjectAttachment']),
    onProjectAccountingAttachmentTypeSearch(query) {
      this.filteredProjectAccountingAttachmentTypes = [];
      console.log(this.getEnumValues('ProjectAccountingAttachmentType'));
      this.getEnumValues('ProjectAccountingAttachmentType').map((projectAttachmentType) => {
        if (
          this.$t(projectAttachmentType)
            .toLowerCase()
            .indexOf(query) >= 0
        ) {
          this.filteredProjectAccountingAttachmentTypes.push(projectAttachmentType);
        }
      });
    },
    async onUploadProjectAttachments(payload) {
      for (const file of payload.files) {
        await this.uploadProjectAttachment({
          file,
          parentId: payload.parentId,
          parentType: payload.parentType,
        });
      }
      this.isAccountingAttachmentUploadComplete = true;
    },
    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },

  async mounted() {
    await this.fetchEnumValues('ProjectAccountingAttachmentType');
    this.filteredProjectAccountingAttachmentTypes = this.getEnumValues(
      'ProjectAccountingAttachmentType'
    );
  },
};
</script>

<style lang="scss" scoped>
.verification-flex {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.verification-flex > div {
  flex: 1;
  min-width: 350px;
}
</style>
