<template>
  <div v-if="project" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <ProjectTimeline :project="project" />
    <b-card-group deck>
      <ProjectCardState :project="project" />
      <ProjectCardCommunication :project="project" />
      <ProjectCardOverview :project="project" :projectNumber="projectNumberByClientContext" />
      <ProjectCardAudit :project="project" :projectNumber="projectNumberByClientContext" />
    </b-card-group>

    <b-tabs content-class="" fill v-model="tabIndex">
      <b-tab v-if="isGuest" :title="$t('measurement')">
        <ProjectMeasurementReport
          :project="project"
          v-on:project-measurement-report-attachment-list:delete="onDeleteProjectAttachment"
        />
      </b-tab>

      <b-tab v-if="isGuest" :title="$t('acceptanceReport')">
        <ProjectAcceptanceReport
          :project="project"
          v-on:project-report-attachment-list:delete="onDeleteProjectAttachment"
        />
      </b-tab>
      <b-tab v-if="isGuest" :title="$t('serviceReport')">
        <ProjectServiceReport
          :project="project"
          v-on:project-report-attachment-list:delete="onDeleteProjectAttachment"
        />
      </b-tab>
      <template v-else>
        <b-tab ref="internal" data-key="internal" :title="$t('internal')">
          <ProjectInternal :project="project" />
        </b-tab>

        <b-tab ref="customer" data-key="customer" :title="$t('customer')">
          <ProjectCustomer :project="project" />
        </b-tab>

        <b-tab ref="configuration" data-key="configuration" :title="$t('configuration')">
          <ProjectSolarPlant :project="project" />
        </b-tab>

        <b-tab ref="technicalReview" :title="$t('technicalReview')" data-key="technicalReview">
          <ProjectTechnicalReview :project="project" />
        </b-tab>

        <b-tab ref="orders" :title="$t('orders')" data-key="orders" v-if="isAdmin">
          <ProjectMaterial :project="project" />
        </b-tab>

        <b-tab ref="installation" data-key="installation" :title="$t('installation')">
          <ProjectInstallation :project="project" />
        </b-tab>

        <b-tab :title="$t('powerCompany')">
          <b-row>
            <b-col lg="6" md="6" sm="12">
              <legend>
                <strong>{{ $t('powerCompany') }}</strong>
              </legend>

              <b-form-group
                horizontal
                :label="$t('powerCompany')"
                label-for="powerCompany"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
                :invalid-feedback="invalidFeedbackNA"
                :state="requirementsValidState('powerCompany')"
              >
                <multiselect
                  :placeholder="$t('powerCompany')"
                  v-model="project.powerCompany"
                  id="powerCompany"
                  :options="getPowerCompanies"
                  :allow-empty="false"
                  :show-labels="false"
                  track-by="company"
                  label="company"
                >
                </multiselect>
              </b-form-group>

              <legend>
                <strong>EVU Stammdaten</strong>
              </legend>
              <div class="form-grid-container-left">
                <div class="label">{{ $t('street') }}</div>
                <div v-if="project.powerCompany">
                  <strong>{{ project.powerCompany.street }}</strong>
                </div>
                <div v-else>N/A</div>

                <div class="label">{{ $t('zip') }}</div>
                <div v-if="project.powerCompany">
                  <strong>{{ project.powerCompany.zip }}</strong>
                </div>
                <div v-else>N/A</div>

                <div class="label">{{ $t('city') }}</div>
                <div v-if="project.powerCompany">
                  <strong>{{ project.powerCompany.city }}</strong>
                </div>
                <div v-else>N/A</div>

                <div class="label">{{ $t('cosinusPhi') }}</div>
                <div v-if="project.powerCompany">
                  <strong>{{ project.powerCompany.cosinusPhi }}</strong>
                </div>
                <div v-else>N/A</div>

                <div class="label">{{ $t('operatorOnSiteElectricMeterChange') }}</div>
                <div v-if="project.powerCompany">
                  <strong>{{ $t(project.powerCompany.operatorOnSiteElectricMeterChange) }}</strong>
                </div>
                <div v-else>N/A</div>

                <div class="label">{{ $t('website') }}</div>
                <div v-if="project.powerCompany && project.powerCompany.website">
                  <a :href="project.powerCompany.website" target="_blank">{{
                    project.powerCompany.website
                  }}</a>
                </div>
                <div v-else>N/A</div>

                <div class="label">{{ $t('description') }}</div>
                <div
                  v-if="project.powerCompany && project.powerCompany.description"
                  v-html="project.powerCompany.description"
                ></div>
                <div v-else>N/A</div>
              </div>

              <legend>
                <strong>{{ $t('measurementPointOperator') }}</strong>
              </legend>

              <b-form-group
                horizontal
                :label="$t('measurementPointOperator')"
                label-for="measurementPointOperator"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <multiselect
                  :placeholder="$t('powerCompany')"
                  v-model="project.measurementPointOperator"
                  id="measurementPointOperator"
                  :options="getMeasurementPointOperators"
                  :allow-empty="true"
                  :show-labels="false"
                  track-by="name"
                  label="name"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }} - {{ option.msbNumber }}
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    {{ option.name }} - {{ option.msbNumber }}
                  </template>
                </multiselect>
              </b-form-group>

              <legend>
                <strong>{{ $t('documentAttachments') }}</strong>
              </legend>
              <AttachmentList
                v-if="project.powerCompany"
                :attachments="project.powerCompany.documentAttachments"
                attachmentType="documentAttachments"
                :deletable="false"
              ></AttachmentList>
            </b-col>

            <b-col lg="6" md="6" sm="12">
              <legend>
                <strong>EVU Kommunikation</strong>
              </legend>

              <b-form-group
                horizontal
                :label="$t('netRegistrationSentAt')"
                label-for="netRegistrationSentAt"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <Calendar
                  v-model="netRegistrationSentAt"
                  selectionMode="single"
                  class="p-column-filter"
                  dateFormat="dd.mm.yy"
                  :placeholder="$t('date')"
                  :showWeek="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="2019:2025"
                >
                </Calendar>
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('powerCompanyProjectNumber')"
                label-for="powerCompanyProjectNumber"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="project.powerCompanyProjectNumber"
                  type="text"
                  :placeholder="$t('powerCompanyProjectNumber')"
                />
              </b-form-group>
              <hr />
              <b-form-group
                horizontal
                :label="$t('injectionConfirmationReceivedAt')"
                label-for="injectionConfirmationReceivedAt"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <Calendar
                  v-model="injectionConfirmationReceivedAt"
                  selectionMode="single"
                  class="p-column-filter"
                  dateFormat="dd.mm.yy"
                  :placeholder="$t('date')"
                  :showWeek="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="2019:2025"
                >
                </Calendar>
              </b-form-group>

              <hr />
              <b-form-group
                horizontal
                :label="$t('operationRegistrationSentAt')"
                label-for="operationRegistrationSentAt"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <Calendar
                  v-model="operationRegistrationSentAt"
                  selectionMode="single"
                  class="p-column-filter"
                  dateFormat="dd.mm.yy"
                  :placeholder="$t('date')"
                  :showWeek="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="2019:2025"
                >
                </Calendar>
              </b-form-group>

              <b-form-group
                horizontal
                :label="$t('powerCompanyProjectNumber2')"
                label-for="powerCompanyProjectNumber2"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="project.powerCompanyProjectNumber2"
                  type="text"
                  :placeholder="$t('powerCompanyProjectNumber2')"
                />
              </b-form-group>
              <hr />
              <b-form-group
                horizontal
                :label="$t('completionMessageSentAt')"
                label-for="completionMessageSentAt"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <Calendar
                  v-model="completionMessageSentAt"
                  selectionMode="single"
                  class="p-column-filter"
                  dateFormat="dd.mm.yy"
                  :placeholder="$t('date')"
                  :showWeek="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="2019:2025"
                >
                </Calendar>
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('powerCompanyProjectNumber3')"
                label-for="powerCompanyProjectNumber3"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="project.powerCompanyProjectNumber3"
                  type="text"
                  :placeholder="$t('powerCompanyProjectNumber3')"
                />
              </b-form-group>
              <hr />

              <b-form-group
                horizontal
                :label="$t('powerCompanyClearanceAt')"
                label-for="powerCompanyClearanceAt"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <Calendar
                  v-model="powerCompanyClearanceAt"
                  selectionMode="single"
                  class="p-column-filter"
                  dateFormat="dd.mm.yy"
                  :placeholder="$t('date')"
                  :showWeek="true"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="2019:2025"
                >
                </Calendar>
              </b-form-group>
              <legend>
                <strong>{{ $t('notes') }}</strong>
              </legend>
              <b-form-group horizontal breakpoint="md">
                <ckeditor
                  :editor="editor"
                  v-model="project.powerCompanyDescription"
                  :config="editorConfig"
                ></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab ref="documents" data-key="documents" :title="$t('documents')">
          <ProjectDocuments :project="project" />
        </b-tab>

        <b-tab
          ref="registration"
          data-key="registration"
          v-if="isAdmin"
          :title="$t('registration')"
        >
          <ProjectRegistration :project="project" />
        </b-tab>

        <b-tab ref="offers_invoices" data-key="offers_invoices" :title="$t('offers_invoices')">
          <ProjectAccounting :project="project" />
        </b-tab>

        <b-tab
          ref="email"
          data-key="email"
          :title="$t('email')"
          v-if="project.client && project.client.name !== 'SOMV Project Consulting GmbH'"
        >
          <ProjectMessages :project="project" :selectedAppointment="selectedAppointment" />
        </b-tab>

        <b-tab ref="Freigabe" data-key="Freigabe" v-if="isAdmin" title="Freigabe">
          <EmployerApproval :project="project" />
        </b-tab>

        <b-tab ref="measurement" data-key="measurement" :title="$t('measurementReport')">
          <ProjectMeasurementReport
            :project="project"
            v-on:project-measurement-report-attachment-list:delete="onDeleteProjectAttachment"
          />
        </b-tab>

        <b-tab ref="acceptanceReport" data-key="acceptanceReport" :title="$t('acceptanceReport')">
          <ProjectAcceptanceReport
            :project="project"
            v-on:project-report-attachment-list:delete="onDeleteProjectAttachment"
          />
        </b-tab>

        <b-tab ref="serviceReport" data-key="serviceReport" :title="$t('serviceReport')">
          <ProjectServiceReport
            :project="project"
            v-on:project-report-attachment-list:delete="onDeleteProjectAttachment"
          />
        </b-tab>

        <b-tab ref="qualityReport" data-key="qualityReport" :title="$t('qualityReport')">
          <ProjectQualityReport
            :project="project"
            v-on:project-report-attachment-list:delete="onDeleteProjectAttachment"
          />
        </b-tab>

        <b-tab v-if="isAdmin" :title="$t('assessmentReport')">
          <ProjectAssessmentReport
            :project="project"
            v-on:project-report-attachment-list:delete="onDeleteProjectAttachment"
          />
        </b-tab>

        <b-row>
          <b-col lg="12" md="12" sm="12">
            <ButtonBar
              :showPreviousButton="buttons.showPreviousButton"
              :showNextButton="buttons.showNextButton"
              :showDeleteButton="buttons.showDeleteButton"
              :model="project"
              modelEditRoute="ProjectEditPage"
              modelListRoute="ProjectsPaginatedPage"
              modelRouteParamName="projectNumber"
              :nextModelNumber="project.nextProjectNumber"
              :previousModelNumber="project.previousProjectNumber"
              :updateCallback="updateProject"
              :createCallback="createProject"
              :deleteCallback="deleteProject"
              :fetchCallback="fetchProjects"
            />
          </b-col>
        </b-row> </template
    ></b-tabs>

    <b-card-group deck class="mt-4">
      <ProjectCardPosts :project="project" />
    </b-card-group>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import Multiselect from 'vue-multiselect';
import { mapGetters, mapActions } from 'vuex';
import { getYesNoStateColor, getProjectStateColor } from '@/helpers/colors';
import AttachmentList from '@/components/AttachmentList';
import ProjectTechnicalReview from '@/views/Projects/ProjectTechnicalReview';
import ProjectMessages from '@/views/Projects/ProjectMessages';
import EmployerApproval from '@/views/Projects/EmployerApproval';
import Calendar from 'primevue/calendar';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ProjectMeasurementReport from '@/views/Projects/ProjectMeasurementReport';
import ProjectAcceptanceReport from '@/views/Projects/ProjectAcceptanceReport';
import ProjectServiceReport from '@/views/Projects/ProjectServiceReport';
import ProjectSolarPlant from '@/views/Projects/ProjectSolarPlant';
import ProjectInternal from '@/views/Projects/ProjectInternal';
import ProjectCustomer from '@/views/Projects/ProjectCustomer';
import ProjectAccounting from '@/views/Projects/ProjectAccounting';
import ProjectRegistration from '@/views/Projects/ProjectRegistration';
import ProjectCardOverview from '@/views/Projects/ProjectCardOverview';
import ProjectCardCommunication from '@/views/Projects/ProjectCardCommunication';
import ProjectCardState from '@/views/Projects/ProjectCardState';
import ProjectCardPosts from '@/views/Projects/ProjectCardPosts';
import ProjectCardAudit from '@/views/Projects/ProjectCardAudit';
import ProjectDocuments from '@/views/Projects/ProjectDocuments';
import ProjectInstallation from '@/views/Projects/ProjectInstallation';
import ProjectTimeline from '@/views/Projects/ProjectTimeline';
import ButtonBar from '@/components/ButtonBar.vue';
import ProjectMaterial from '@/views/Projects/ProjectMaterial';
import ProjectQualityReport from '@/views/Projects/ProjectQualityReport';
import ProjectAssessmentReport from '@/views/Projects/ProjectAssessmentReport';

export default {
  name: 'ProjectEditPage',
  components: {
    Breadcrumbs,
    Multiselect,
    AttachmentList,
    ProjectTechnicalReview,
    ProjectMessages,
    EmployerApproval,
    Calendar,
    ckeditor: CKEditor.component,
    ProjectMeasurementReport,
    ProjectSolarPlant,
    ProjectInternal,
    ProjectCustomer,
    ProjectAccounting,
    ButtonBar,
    ProjectRegistration,
    ProjectCardOverview,
    ProjectCardCommunication,
    ProjectCardState,
    ProjectDocuments,
    ProjectInstallation,
    ProjectAcceptanceReport,
    ProjectServiceReport,
    ProjectTimeline,
    ProjectMaterial,
    ProjectCardPosts,
    ProjectCardAudit,
    ProjectQualityReport,
    ProjectAssessmentReport,
  },
  props: {
    projectNumber: {
      required: false,
      default: null,
    },
    projectTab: {
      type: String,
      required: false,
      default: '',
    },
    // referer: { default: '', type: String },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      filteredProjectAttachmentTypes: [],
      tabIndex: 0,
      referer: null,
      selectedAppointment: null,
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  filters: {
    toDate(value) {
      // console.log(value);
      return value ? new Date(value) : null;
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isClient', 'getCurrentUser', 'isGuest']),
    ...mapGetters([
      'getFlashMessage',
      'getProject',
      'getProjectByNumber',
      'getPowerCompanies',
      'getSolarPanels',
      'getStorageSystems',
      'getMeasurementPointOperators',
      'getEnumValues',
    ]),

    buttons() {
      return {
        showPreviousButton:
          this.isAdmin && this.project && this.project.previousProjectNumber ? true : false,
        showNextButton:
          this.isAdmin && this.project && this.project.nextProjectNumber ? true : false,
        showDeleteButton: false,
        // this.isAdmin && this.project && this.project.number !== '_new' ? true : false,
      };
    },

    netRegistrationSentAt: {
      get() {
        return this.$calendarFormat(this.project.netRegistrationSentAt);
      },
      set(value) {
        this.project.netRegistrationSentAt = value;
      },
    },
    injectionConfirmationReceivedAt: {
      get() {
        return this.$calendarFormat(this.project.injectionConfirmationReceivedAt);
      },
      set(value) {
        this.project.injectionConfirmationReceivedAt = value;
      },
    },
    operationRegistrationSentAt: {
      get() {
        return this.$calendarFormat(this.project.operationRegistrationSentAt);
      },
      set(value) {
        this.project.operationRegistrationSentAt = value;
      },
    },
    completionMessageSentAt: {
      get() {
        return this.$calendarFormat(this.project.completionMessageSentAt);
      },
      set(value) {
        this.project.completionMessageSentAt = value;
      },
    },
    powerCompanyClearanceAt: {
      get() {
        return this.$calendarFormat(this.project.powerCompanyClearanceAt);
      },
      set(value) {
        this.project.powerCompanyClearanceAt = value;
      },
    },
    project() {
      return this.getProjectByNumber(this.number);
    },
    projectNumberByClientContext() {
      let number = '';
      if (this.project.number === '_new') {
        number = this.$t('_new');
      }

      if (
        this.isClient &&
        this.project.number &&
        this.project.number !== '_new' &&
        this.project.clientProjectNumber
      ) {
        number = this.project.clientProjectNumber.toString().padStart(4, '0');
      }

      if (this.isAdmin && this.project.number && this.project.number !== '_new') {
        number = this.project.number.toString().padStart(4, '0');
      }
      return number;
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Projekte', route: { name: 'ProjectsPaginatedPage' } },
        {
          name: this.projectNumberByClientContext,
        },
      ];
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions([
      'initProject',
      'fetchProjects',
      'fetchProjectByNumber',
      'updateProject',
      'createProject',
      'deleteProject',
      'fetchOriginalInstallationTypes',
      'fetchPowerCompanies',
      'fetchSolarPanels',
      'fetchStorageSystems',
      'addEmptyInstallationAppointment',
      'uploadProjectAttachment',
      'deleteProjectAttachment',
      'fetchMeasurementPointOperators',
      'fetchEnumValues',
      'fetchReportTemplateImages',
    ]),
    setActiveTab() {
      const tabRefs = Object.keys(this.$refs).map((ref) => this.$refs[ref]);
      const tabKeys = tabRefs.map((tab) =>
        tab && tab.$el ? tab.$el.getAttribute('data-key') : undefined
      );
      console.log('Available tab keys:', tabKeys);

      if (!this.projectTab) {
        console.log('No projectTab prop provided.');
        return;
      }

      const tabIndex = tabRefs.findIndex(
        (tab) => tab && tab.$el && tab.$el.getAttribute('data-key') === this.projectTab
      );
      if (tabIndex !== -1) {
        this.tabIndex = tabIndex;
      } else {
        console.log(`Tab with key "${this.projectTab}" not found.`);
      }
    },
    isDisabled(e) {
      e.preventDefault();
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    projectStateColor(state) {
      return getProjectStateColor(state);
    },
    onAddInstallationAppointment() {
      this.addEmptyInstallationAppointment(this.project);
    },

    /** @todo changes to project are lost */
    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },

    async setup() {
      await this.fetchOriginalInstallationTypes();
      await this.fetchEnumValues('ProjectAttachmentType');
      await this.fetchEnumValues('EmployerProjectAttachmentType');
      await this.fetchPowerCompanies();
      await this.fetchSolarPanels();
      await this.fetchStorageSystems();
      await this.fetchMeasurementPointOperators();
      await this.fetchReportTemplateImages();

      this.filteredProjectAttachmentTypes = this.isClient
        ? this.getEnumValues('EmployerProjectAttachmentType')
        : this.getEnumValues('ProjectAttachmentType');
    },

    async setupClientContext() {},

    async setupAdminContext() {},

    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
  },
  async mounted() {
    if (this.isClient) {
      await this.setupClientContext();
    }
    if (this.isAdmin) {
      await this.setupAdminContext();
    }
    await this.setup();

    if (!this.project && this.projectNumber) {
      this.number = this.projectNumber;
      await this.fetchProjectByNumber(this.number).then((errors) => {
        if (errors && errors.length > 0 && errors[0].extensions.code === 'FORBIDDEN') {
          this.$router.push('/403');
        }
      });
    } else {
      this.initProject();
      this.number = '_new';
    }

    if (this.isClient) {
      this.project.client = this.getCurrentUser.client;
    }

    /**
     * Go to installation tab if coming from RouteSchedulingPage
     */
    if (this.referer && this.referer.name === 'RouteSchedulingPage') {
      this.tabIndex = 4; // change tab to Installation
    }
    if (this.projectTab) {
      this.setActiveTab();
    }
  },

  /**
   * if only parameters of the route have changed the component will be reused
   * we have to update all the data before the route updates
   * @param to
   * @param from
   * @param next
   */
  async beforeRouteUpdate(to, from, next) {
    if (to.params.projectNumber && to.params.projectNumber !== this.projectNumber) {
      this.$store.commit('SET_PROJECT_DEFAULT_STATE');
      this.number = to.params.projectNumber;
      await this.fetchProjectByNumber(this.number);
      await this.setup();
      next();
    } else {
      next(false);
    }
  },

  /**
   * Save referer to current view
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.referer = from;
    });
  },
};
</script>

<style scoped lang="scss">
.spinner {
  top: 4px;
  position: relative;
}

.customer-card {
  max-width: 50rem;
}
.solar-plant-card {
  max-width: 35rem;
}
.calculations-card {
  max-width: 25rem;
}
.solar-plant-data-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 0.7em;
}
.calculations-grid-container {
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1em;
  row-gap: 0.7em;
}
.form-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container div {
  padding: 0.4em;
}
.form-grid-container-left {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container-left div {
  padding: 0.4em;
}
.form-grid-container-left .label {
  text-align: right;
}

.trash-installation-appointment {
  text-align: right;
}
.trash-installation-appointment i {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}

li > .badge {
  font-size: 0.8em;
  padding: 3px 4px 4px 4px;
}
.ck-content {
  min-height: 80px;
}
</style>
