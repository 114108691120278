<template>
  <b-card :title="$t('projectState')" class="mb-4 project-state-card">
    <b-card-text class="project-state-card-grid-container">
      <div>{{ $t('projectState') }}:</div>
      <b-badge :variant="getProjectStateColor(project.projectState)">
        {{ $t(project.projectState) }}
      </b-badge>

      <template v-if="project.deferredState && project.deferredState.deferred === 'YES'">
        <div>{{ $t('deferredState') }}:</div>
        <div>
          <b-badge
            id="popover-deferredState"
            :variant="yesNoStateReversedColor(project.deferredState.deferred)"
          >
            {{ $t(project.deferredState.deferred) }}
          </b-badge>
          <b-popover target="popover-deferredState" triggers="hover" placement="right">
            <template #title>{{ $t('deferredState') }}</template>
            <div class="popover-grid-container2">
              <div>
                <strong>{{ $t('reason') }}</strong>
              </div>
              <div>{{ $t(project.deferredState.reason) }}</div>
              <div>
                <strong>{{ $t('description') }}</strong>
              </div>
              <div>{{ project.deferredState.description }}</div>
              <div>
                <strong>{{ $t('comment') }}</strong>
              </div>
              <div>{{ project.deferredState.comment }}</div>
            </div>
          </b-popover>
        </div>
      </template>

      <div>
        {{ $t('requirements') }} <strong>{{ $t('netRegistration_short') }}</strong>
        {{ $t('fullfilled') }}:
      </div>
      <div>
        <b-badge
          id="popover-netRegistrationRequirements"
          :variant="getYesNoStateColor(project.netRegistrationRequirementsFullfilledState)"
        >
          {{ $t(project.netRegistrationRequirementsFullfilledState) }}
        </b-badge>
        <b-popover target="popover-netRegistrationRequirements" triggers="hover" placement="right">
          <template #title>{{ $t('netRegistrationRequirements') }}</template>
          <div
            class="popover-grid-container"
            v-for="(requirement, index) in project.netRegistrationRequirements"
            :key="index"
          >
            <div>{{ $t(requirement.name) }}:</div>
            <div>
              <b-badge :variant="getYesNoStateColor(requirement.fullfilledState)">
                {{ $t(requirement.fullfilledState) }}
              </b-badge>
            </div>
          </div>
        </b-popover>
      </div>
      <div>
        {{ $t('requirements') }} <strong>{{ $t('operationRegistration_short') }}</strong>
        {{ $t('fullfilled') }}:
      </div>
      <div class="">
        <b-badge
          id="popover-operationRegistrationRequirements"
          :variant="getYesNoStateColor(project.operationRegistrationRequirementsFullfilledState)"
        >
          {{ $t(project.operationRegistrationRequirementsFullfilledState) }}
        </b-badge>
        <b-popover
          target="popover-operationRegistrationRequirements"
          triggers="hover"
          placement="right"
        >
          <template #title>{{ $t('operationRegistrationRequirements') }}</template>
          <div
            class="popover-grid-container"
            v-for="(requirement, index) in project.operationRegistrationRequirements"
            :key="index"
          >
            <div>{{ $t(requirement.name) }}:</div>
            <div>
              <b-badge :variant="getYesNoStateColor(requirement.fullfilledState)">
                {{ $t(requirement.fullfilledState) }}
              </b-badge>
            </div>
          </div>
        </b-popover>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  getYesNoStateColor,
  getProjectStateColor,
  yesNoStateReversedColor,
} from '@/helpers/colors';

export default {
  props: {
    project: { type: Object },
  },
  methods: {
    getYesNoStateColor,
    getProjectStateColor,
    yesNoStateReversedColor,
  },
};
</script>

<style scoped lang="scss">
.project-state-card {
  max-width: 30rem;
}
.project-state-card-grid-container {
  display: grid;
  grid-template-columns: 5fr 1fr;
  column-gap: 1em;
  row-gap: 0.5em;
}
.popover-grid-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
}
.popover-grid-container div {
  padding: 0.2em;
}
.popover-grid-container2 {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.popover-grid-container2 div {
  padding: 0.2em;
}
</style>
