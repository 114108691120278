<template>
  <div>
    <AccordionCard :state="cards.zsLocationState">
      <template v-slot:header>
        {{ $t('zsLocationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('zsLocationState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.zsLocationState"
            :options="getEnumValues('HouseLocationState')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.zsWithCoverAttachments">
      <template v-slot:header>
        {{ $t('zsWithCoverAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('zsWithCoverAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsWithCoverAttachments"
            attachmentType="serviceZsWithCoverAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.zsWithoutCoverAttachments">
      <template v-slot:header>
        {{ $t('zsWithoutCoverAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('zsWithoutCoverAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsWithoutCoverAttachments"
            attachmentType="serviceZsWithoutCoverAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceZsSerialNumberAttachments">
      <template v-slot:header>
        {{ $t('serviceZsSerialNumberAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceZsSerialNumberAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsSerialNumberAttachments"
            attachmentType="serviceZsSerialNumberAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceZsPostCounterAreaAttachments">
      <template v-slot:header>
        {{ $t('serviceZsPostCounterAreaAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceZsPostCounterAreaAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsPostCounterAreaAttachments"
            attachmentType="serviceZsPostCounterAreaAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.gakAttachments">
      <template v-slot:header>
        {{ $t('gakAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('gakAttachments') }}</div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.gakAttachments"
            attachmentType="gakAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    // Textarea,
    // DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isZsWithCoverAttachmentsUploadComplete: false,
      isZsWithoutCoverAttachmentsUploadComplete: false,
      isZsOptionalAttachmentsUploadComplete: false,
      isserviceZsSerialNumberAttachmentsUploadComplete: false,
      isserviceZsPostCounterAreaAttachmentsUploadComplete: false,
      isserviceZsPreCounterAreaAttachmentsUploadComplete: false,
      isserviceZsDistributionAreaAttachmentsUploadComplete: false,
      isserviceAdditionalSubDistributionAttachmentsUploadComplete: false,
      isgakAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'fetchEnumValues']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    await this.fetchEnumValues('HouseLocationState');
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isZsWithCoverAttachmentsUploadComplete = true;
          this.isZsWithoutCoverAttachmentsUploadComplete = true;
          this.isZsOptionalAttachmentsUploadComplete = true;
          this.isserviceZsPostCounterAreaAttachmentsUploadComplete = true;
          this.isserviceZsPreCounterAreaAttachmentsUploadComplete = true;
          this.isserviceZsDistributionAreaAttachmentsUploadComplete = true;
          this.isserviceAdditionalSubDistributionAttachmentsUploadComplete = true;
          this.isserviceZsSerialNumberAttachmentsUploadComplete = true;
          this.isgakAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
