<template>
  <div>
    <AccordionCard :state="cards.qcellsWallboxContractState">
      <template v-slot:header>
        {{ $t('qcellsWallboxContractState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('qcellsWallboxContractState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.qcellsWallboxContractState"
            :options="getEnumValues('YesNo')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxCablePathNotes">
      <template v-slot:header>
        {{ $t('serviceWallboxCablePathNotes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxCablePathNotes') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxCablePathNotes"
            attachmentType="serviceWallboxCablePathNotes"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxLocationAttachments">
      <template v-slot:header>
        {{ $t('serviceWallboxLocationAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxLocationAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxLocationAttachments"
            attachmentType="serviceWallboxLocationAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxAccessCardAttachments">
      <template v-slot:header>
        {{ $t('serviceWallboxAccessCardAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxAccessCardAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxAccessCardAttachments"
            attachmentType="serviceWallboxAccessCardAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceFiLsWallboxAttachments">
      <template v-slot:header>
        {{ $t('serviceFiLsWallboxAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceFiLsWallboxAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceFiLsWallboxAttachments"
            attachmentType="serviceFiLsWallboxAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  components: {
    AccordionCard,
    AttachmentGallery,
    // DropzoneUpload,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isserviceWallboxLocationAttachmentsUploadComplete: false,
      isserviceWallboxCablePathNotesUploadComplete: false,
      isserviceWallboxAttachmentsUploadComplete: false,
      isserviceWallboxAccessCardAttachmentsUploadComplete: false,
      isserviceFiLsWallboxAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', '']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    // async on(event) {
    //   await this.({
    //     projectId: this.project.id,
    //     attachmentId: event.attachmentId,
    //     attachmentType: event.attachmentType,
    //   });
    // },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isserviceWallboxLocationAttachmentsUploadComplete = true;
          this.isserviceWallboxCablePathNotesUploadComplete = true;
          this.isserviceWallboxAttachmentsUploadComplete = true;
          this.isserviceWallboxAccessCardAttachmentsUploadComplete = true;
          this.isserviceFiLsWallboxAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
