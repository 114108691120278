<template>
  <div class="project-acceptance-report" v-if="project">
    <b-row class="mt-4">
      <b-col lg="12" md="12" sm="12">
        <legend>
          <strong>{{ $t('qualityReport') }}: {{ $t('collected_data') }}</strong>
        </legend>
        <MobileQualityReport :project="project" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MobileQualityReport from '@/views/Projects/MobileQualityReport';
import ZipDownload from '@/components/ZipDownload';
import ButtonWait from '@/components/ButtonWait.vue';
import AttachmentList from '@/components/AttachmentList';
import VerificationState from '@/components/VerificationState.vue';
import Card from 'primevue/card';

export default {
  components: {
    // ButtonWait,
    // AttachmentList,
    MobileQualityReport,
    // ZipDownload,
    // VerificationState,
    // Card,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      attachmentType: 'qualityReportAttachments',
    };
  },
  computed: {
    ...mapGetters('auth', ['isGuest']),
  },
  methods: {
    ...mapActions(['buildReport', 'fetchReportAttachmentsBuildState']),
    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startReportBuild() {
      if (this.project) {
        await this.buildReport(this.project.id);
      }
    },
    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    fetchBuildState() {
      this.fetchReportAttachmentsBuildState(this.project.number);
      return this.project.reportAttachmentsBuildState;
    },
    /**
     * Bubble up AttachmentList delete event
     */
    onDeleteAttachment(event) {
      this.$emit('project-report-attachment-list:delete', {
        attachmentId: event.attachmentId,
        attachmentType: this.attachmentType,
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.verification-flex {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.verification-flex > div {
  flex: 1;
  min-width: 350px;
}
</style>
