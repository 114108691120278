<template>
  <div>
    <AccordionCard :state="cards.cableManagementC2State">
      <template v-slot:header>
        {{ $t('cableManagementC2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableManagementC2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.cableManagementC2State"
            :options="getEnumValues('YesNo')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCableManagementAttachments">
      <template v-slot:header>
        {{ $t('cableManagementAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('cableManagementAttachments') }}</div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCableManagementAttachments"
            attachmentType="serviceCableManagementAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCableAlongFacadeAttachments">
      <template v-slot:header>
        {{ $t('cableAlongFacadeAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('cableAlongFacadeAttachments') }}</div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('cableAlongFacadeAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCableAlongFacadeAttachments"
            attachmentType="serviceCableAlongFacadeAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    // DropzoneUpload,
    // AccordionCardOptionalInput,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      iscableManagementAttachmentsUploadComplete: false,
      iscableAlongFacadeAttachmentsUploadComplete: false,
      iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.iscableManagementAttachmentsUploadComplete = true;
          this.iscableAlongFacadeAttachmentsUploadComplete = true;
          this.iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
