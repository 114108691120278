<template>
  <div>
    <AccordionCard :state="cards.electricMeterCount">
      <template v-slot:header>
        {{ $t('electricMeterCount') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('electricMeterCount') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.electricMeterCount"
            :options="getEnumValues('OneToEightState')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.electricMeterList">
      <template v-slot:header>
        {{ $t('electricMeterList') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('electricMeterList') }}
        </div>
        <div class="flex-column select">
          <InputText v-model="project.serviceReport.electricMeterList" disabled />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.intermediateMeterRequestedState">
      <template v-slot:header>
        {{ $t('intermediateMeterRequestedState') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('intermediateMeterRequestedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.intermediateMeterRequestedState"
            :options="getEnumValues('YesNo')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.meterCombinationRequestedState">
      <template v-slot:header>
        {{ $t('meterCombinationRequestedState') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('meterCombinationRequestedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.meterCombinationRequestedState"
            :options="getEnumValues('YesNo')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.disassembledMetersOnSiteState">
      <template v-slot:header>
        {{ $t('disassembledMetersOnSiteState') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('disassembledMetersOnSiteState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.disassembledMetersOnSiteState"
            :options="getEnumValues('YesNo')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceMainElectricMeterAttachments">
      <template v-slot:header>
        {{ $t('serviceMainElectricMeterAttachments') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceMainElectricMeterAttachments') }}
        </div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceMainElectricMeterAttachments"
            attachmentType="serviceMainElectricMeterAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceSeconaryElectricMeterAttachments">
      <template v-slot:header>
        {{ $t('serviceSeconaryElectricMeterAttachments') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceSeconaryElectricMeterAttachments') }}
        </div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceSeconaryElectricMeterAttachments"
            attachmentType="serviceSeconaryElectricMeterAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
// import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    // DropzoneUpload,
    SelectButton,
    InputText,
    // AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isinverterAttachmentsUploadComplete: false,
      isinverterSerialNumberAttachmentsUploadComplete: false,
      ismateboxSerialNumberAttachmentsUploadComplete: false,
      isstorageSerialNumberAttachmentsUploadComplete: false,
      isdongleSerialNumberAttachmentsUploadComplete: false,
      isServiceDisassembledMetersAttachmentsUploadComplete: false,
      isServiceMainElectricMeterAttachmentsUploadComplete: false,
      isServiceSeconaryElectricMeterAttachmentsUploadComplete: false,
      isserviceDisassembledMetersAttachmentsUploadComplete: false,
      isserviceMainElectricMeterAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isinverterAttachmentsUploadComplete = true;
          this.isinverterSerialNumberAttachmentsUploadComplete = true;
          this.ismateboxSerialNumberAttachmentsUploadComplete = true;
          this.isstorageSerialNumberAttachmentsUploadComplete = true;
          this.isdongleSerialNumberAttachmentsUploadComplete = true;
          this.isServiceDisassembledMetersAttachmentsUploadComplete = true;
          this.isServiceMainElectricMeterAttachmentsUploadComplete = true;
          this.isServiceSeconaryElectricMeterAttachmentsUploadComplete = true;
          this.isserviceDisassembledMetersAttachmentsUploadComplete = true;
          this.isserviceMainElectricMeterAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
