<template>
  <div>
    <AccordionCard :state="cards.serviceStringPlanAttachments">
      <template v-slot:header>
        {{ $t('stringPlanAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringPlanAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('stringPlanAttachments_description') }}

          {{ $t('panelBottomViewAttachments_description') }} <br />
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceStringPlanAttachments"
            attachmentType="serviceStringPlanAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.stringPlanAvailableState">
      <template v-slot:header>
        {{ $t('stringPlanAvailableState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringPlanAvailableState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.stringPlanAvailableState"
            :options="getEnumValues('YesNo')"
            disabled
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceClampAreaAttachments">
      <template v-slot:header>
        {{ $t('clampAreaAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('clampAreaAttachments') }}</div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceClampAreaAttachments"
            attachmentType="serviceClampAreaAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceAllPanelsVisibleAttachments">
      <template v-slot:header>
        {{ $t('allPanelsVisibleAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('allPanelsVisibleAttachments') }}</div>
        <div class="flex-column select"></div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceAllPanelsVisibleAttachments"
            attachmentType="serviceAllPanelsVisibleAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceRailOverlapAttachments">
      <template v-slot:header>
        {{ $t('serviceRailOverlapAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceRailOverlapAttachments') }}</div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceRailOverlapAttachments"
            attachmentType="serviceRailOverlapAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceTrayfDistanceAttachments">
      <template v-slot:header>
        {{ $t('trayfDistanceAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('trayfDistanceAttachments') }}</div>
        <div class="flex-column select"></div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceTrayfDistanceAttachments"
            attachmentType="serviceTrayfDistanceAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.servicePanelBottomViewAttachments">
      <template v-slot:header>
        {{ $t('panelBottomViewAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('panelBottomViewAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.servicePanelBottomViewAttachments"
            attachmentType="servicePanelBottomViewAttachments"
            :sortByName="false"
          ></AttachmentGallery>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    // AccordionCardOptionalInput,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isstringPlanAttachmentsUploadComplete: false,
      isallPanelsVisibleAttachmentsUploadComplete: false,
      ispanelBottomViewAttachmentsUploadComplete: false,
      israilOverlapAttachmentsUploadComplete: false,
      isclampAreaAttachmentsUploadComplete: false,
      istrayfDistanceAttachmentsUploadComplete: false,
      isserviceRailOverlapAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isstringPlanAttachmentsUploadComplete = true;
          this.isallPanelsVisibleAttachmentsUploadComplete = true;
          this.ispanelBottomViewAttachmentsUploadComplete = true;
          this.israilOverlapAttachmentsUploadComplete = true;
          this.isclampAreaAttachmentsUploadComplete = true;
          this.istrayfDistanceAttachmentsUploadComplete = true;
          this.isserviceRailOverlapAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
