<template>
  <div class="item-select">
    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      :value="items"
      :loading="isLoading"
      :rowHover="true"
      :lazy="true"
      filterDisplay="row"
      :stateKey="tableStateStore.filterName()"
      :filters.sync="tableStateStore.filters"
      :rows.sync="tableStateStore.pagination.pageSize"
      :sortField="tableStateStore.sorting.sortField"
      :sortOrder="tableStateStore.sorting.sortOrder"
      :first="tableStateStore.skip()"
      stateStorage="local"
      :totalRecords="getItemCount"
      :paginator="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ $t('item_inventory') }}</h4>
          </div>
          <div class="table-header-right">
            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column field="wholesaleNr" :header="$t('itemNumber')" sortable :showFilterMenu="false">
        <template #body="slotProps">{{ slotProps.data.wholesaleNr }}</template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="description" :header="$t('description')" sortable :showFilterMenu="false">
        <template #body="slotProps"><span v-html="slotProps.data.description"></span> </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="unit"
        :header="$t('unit')"
        sortable
        filterMatchMode="equals"
        :headerStyle="{ width: '90px' }"
      >
        <template #body="slotProps"> {{ $t(slotProps.data.unit) }} </template>
      </Column>

      <Column
        field="type"
        :header="$t('type')"
        sortable
        :headerStyle="{ width: '90px' }"
        :showFilterMenu="false"
      >
        <template #body="slotProps"> {{ $t(slotProps.data.type) }} </template>

        <template #filter>
          <MultiSelect
            :value="tableStateStore.customFilters.itemTypes"
            :options="itemTypes"
            @input="onItemTypeFilter"
            optionLabel="label"
            :placeholder="$t('type')"
            display="chip"
            :style="{ 'max-width': '180px' }"
          />
        </template>
      </Column>

      <Column
        field="addRemoveCount"
        :headerStyle="{ width: '90px' }"
        :header="$t('add') + '/' + $t('remove')"
      >
        <template #body="slotProps">
          <div class="item-add-remove-grid">
            <InputText
              type="text"
              v-model="slotProps.data.addRemoveCount"
              class="mr-2"
              style="max-width:50px"
            />
            <button
              class="btn btn-sm btn-success mr-2"
              @click.prevent="onAddItemToProject(slotProps.data)"
            >
              <i class="fa fa-plus" />
            </button>
            <button
              class="btn btn-sm btn-danger "
              @click.prevent="onRemoveItemFromProject(slotProps.data)"
            >
              <i class="fa fa-minus" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

export default {
  name: 'ItemSelect',
  components: {
    DataTable,
    Column,
    InputText,
    MultiSelect,
  },
  props: {
    itemType: { type: String, default: 'STANDARD' },
  },
  data() {
    return {
      userTableStateConfig: {
        name: 'itemselect',
        filters: {
          number: { value: null },
          inventory: { value: null },
          unit: { value: null },
          price: { value: null },
          wholesaleNr: { value: null },
          description: { value: null },
        },
        customFilters: {
          itemTypes: [],
        },
        sorting: { sortField: 'number', sortOrder: 1 },
      },
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'getItems',
      'getItemsByType',
      'getItemCount',
      'getTableState',
      'getEnumValues',
    ]),
    items() {
      return this.getItems;
    },
    defaultSorting() {
      return { sortField: 'number', sortOrder: 1 };
    },
    defaultPagination() {
      return { skip: 0, pageSize: 10 };
    },
    tableStateStore() {
      return this.getTableState(this.userTableStateConfig.name);
    },
    itemTypes() {
      const itemTypes = this.getEnumValues('ItemType').map((itemType) => {
        return { value: itemType, label: this.$t(itemType) };
      });
      return itemTypes;
    },
  },
  methods: {
    ...mapActions([
      'fetchItemsByType',
      'fetchItems',
      'fetchItemsPaginated',
      'initTableState',
      'fetchEnumValues',
    ]),
    onAddItemToProject(item) {
      this.$emit('item-select:add-item', item);
    },
    onRemoveItemFromProject(item) {
      this.$emit('item-select:remove-item', item);
    },

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchItemsPaginated({
        pagination: {
          skip: this.tableStateStore.skip(),
          pageSize: this.tableStateStore.pagination.pageSize,
        },
        sorting: this.tableStateStore.sorting,
        filters: { ...this.tableStateStore.filters, ...this.tableStateStore.customFilters },
      });
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableStateStore.pagination = { page: event.page, pageSize: event.rows };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableStateStore.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },

    /**
     * Remove all filters
     * @param {*} event
     */
    async onRemoveFilters(event) {
      this.tableStateStore.reset();
      await this.loadTableData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      this.tableStateStore.restore(event);
    },

    /**
     * Filter by user roles
     * @param value
     */
    async onItemTypeFilter(value) {
      const filteredItemTypes = {
        itemTypes: this.itemTypes.filter((itemType) => value.includes(itemType)),
      };
      this.tableStateStore.setCustomFilters(filteredItemTypes);
      await this.loadTableData();
    },
  },
  async created() {
    await this.initTableState(this.userTableStateConfig);
  },

  async mounted() {
    await this.fetchEnumValues('ItemType');
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
.item-add-remove-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
h4.page-title {
  font-size: 15px;
}
</style>
